import styled from 'styled-components'
import { Link } from 'gatsby'

import RightArrow from '../../../images/right-white-arrow.svg'

export const CareerIntroWrapper = styled.div`
  padding: 8rem 0 0 0;
  background: radial-gradient(88.74% 72.1% at 57.4% -30.41%, #1d9ed9 0%, #0d3151 100%);
`
export const CareerIntroHero = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.pureWhite};
`

export const Title = styled.h1`
  font-family: 'Barlow';
  font-size: 5rem;
  font-weight: 800;
  line-height: 6rem;
  margin: 5rem 0 1rem 0;

  @media only screen and (max-width: 475px) {
    font-size: 2.5rem;
    line-height: 3rem;
    margin: 2rem 0;
  }
`
export const Description = styled.div`
  width: 75%;
  font-family: 'Barlow';
  font-size: 2.14rem;
  font-weight: 600;
  line-height: 2.85rem;
  margin: 2rem 0 5rem 0;
  p {
    font-size: 1.2rem;
    line-height: 2rem;
    margin: 1.5rem 0;
  }
  a {
    font-weight: bold;
  }
  h3 {
    font-weight: bold;
    margin-top: 3.5rem;
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  @media only screen and (max-width: 475px) {
    font-size: 1.1rem;
    line-height: 1.7rem;
  }
`
export const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5rem 0;

  a {
    text-transform: uppercase;
  }
  span {
    color: ${({ theme }) => theme.colors.lightestGrey};
    font-family: 'Barlow';
    font-weight: 500;
    text-decoration: none;
    font-size: 1.14rem;
    line-height: 1.71rem;
    text-transform: uppercase;
    position: relative;

    &::before {
      content: '';
      display: inline-flex;
      width: 14px;
      height: 11px;
      background-image: url(${RightArrow});
      transform: rotate(90deg);
      background-repeat: no-repeat;
      margin-right: 1rem;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-start;

    span {
      margin: 2.5rem 0;
    }
  }

  @media only screen and (max-width: 475px) {
    margin: 0;
  }
`
export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-family: 'Barlow';
  font-weight: 500;
  text-decoration: none;
  font-size: 1.14rem;
  line-height: 1.71rem;
  position: relative;
  align-self: center;

  &::before {
    content: '';
    display: inline-flex;
    width: 14px;
    height: 11px;
    background-image: url(${RightArrow});
    background-repeat: no-repeat;
    margin-right: 1rem;
  }

  &:hover {
    &::after {
      content: '';
      display: inline-flex;
      position: absolute;
      margin: 0 auto;
      width: 100%;
      height: 6px;
      background-color: ${({ theme }) => theme.colors.vividBlue};
      opacity: 0.3;
      bottom: 3px;
      left: 0;
      right: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    align-self: flex-start;
  }
`
