import styled from 'styled-components'

import checkedGreenIcon from '../../../images/checked-green-icon.svg'

export const CareerContentWrapper = styled.div`
  padding: 5rem 0;
  color: ${({ theme }) => theme.colors.lightestGrey};
  background-color: ${({ theme }) => theme.colors.veryDarkBlue};
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  position: relative;

  &::before {
    content: ' ';
    position: absolute;
    left: 0;
    display: block;
    height: 100%;
    width: 4px;
    background: radial-gradient(90.28% 119.86% at 92.64% 106.63%, #1d9ed9 0%, #a9cf3a 100%);
    box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);
    border-radius: 10px;
    transform: translateY(-10%);
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 4rem;

    &::before {
      display: none;
    }
  }
`
export const Title = styled.h4`
  font-family: 'Barlow';
  font-size: 2.85rem;
  font-weight: 800;
  line-height: 3.57rem;
  margin-bottom: 2.14rem;
  margin-left: 2.14rem;

  @media only screen and (max-width: 768px) {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-left: 0;
  }
`

export const Description = styled.p`
  font-size: 1.14rem;
  line-height: inherit;
  margin-bottom: 4rem;
  margin-left: 2.14rem;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
`
export const RentlioEssentials = styled.div`
  background: radial-gradient(90.28% 119.86% at 92.64% 106.63%, #ed1e79 0%, #20629c 100%);
  box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);
  border-radius: 10px;
  padding: 4rem;
  margin: 7rem 0;

  @media only screen and (max-width: 768px) {
    margin: 1rem 0;
    padding: 2rem 1rem 1rem 1rem;
  }
`
export const ListRow = styled.div`
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const RentlioEssentialsTitle = styled.h2`
  font-family: 'Barlow';
  font-size: 4.28rem;
  font-weight: 800;
  line-height: 5.14rem;
  margin-bottom: 4.85rem;

  @media only screen and (max-width: 768px) {
    font-size: 3rem;
    line-height: 3.2rem;
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    font-size: 2rem;
    line-height: 2.3rem;
    text-align: center;
  }
`
export const List = styled.ul`
  font-size: 1.14rem;
  margin-right: 3rem;
  flex: 1;

  li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      padding-bottom: 1.4rem;
    }
    &::before {
      content: '';
      display: inline-flex;
      width: 22px;
      height: 22px;
      background-image: url(${checkedGreenIcon});
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 1rem;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 1.4rem;
    margin-right: 0;
  }
`
