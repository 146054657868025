import React from 'react'
import { LocaleConsumer } from '../../Layout'

import { CareerIntroWrapper, CareerIntroHero, Title, Description, LinksWrapper, StyledLink } from './styles'

import Container from '../../UI/Container'

const CareerIntro = ({ locale, data }) => {
  const { intro_title_careers, intro_description_careers } = data
  const lang = locale === 'en' ? `/${locale}/team` : '/tim'
  return (
    <LocaleConsumer>
      {({ i18n }) => (
        <CareerIntroWrapper>
          <Container>
            <CareerIntroHero>
              <Title>{intro_title_careers}</Title>
              <Description dangerouslySetInnerHTML={{ __html: intro_description_careers }} />

              <LinksWrapper>
                <span>{i18n.checkJob}</span>
                <StyledLink to={`${lang}`}>{i18n.teamCulture}</StyledLink>
              </LinksWrapper>
            </CareerIntroHero>
          </Container>
        </CareerIntroWrapper>
      )}
    </LocaleConsumer>
  )
}

export default CareerIntro
