import React from 'react'

import {
  CareerContentWrapper,
  Row,
  Column,
  Title,
  Description,
  RentlioEssentials,
  RentlioEssentialsTitle,
  ListRow,
  List
} from './styles'

import Container from '../../UI/Container'

const CareerContent = ({ content }) => {
  const { column_left_careers, column_right_careers, essentials_title, essentials } = content
  return (
    <CareerContentWrapper>
      <Container>
        <Row>
          <Column>
            <Title>{column_left_careers.title}</Title>
            <Description dangerouslySetInnerHTML={{ __html: column_left_careers.description }} />
          </Column>
          <Column>
            <Title>{column_right_careers.title}</Title>
            <Description dangerouslySetInnerHTML={{ __html: column_right_careers.description }} />
          </Column>
        </Row>
        <RentlioEssentials>
          <RentlioEssentialsTitle>{essentials_title}</RentlioEssentialsTitle>
          <ListRow>
            <List>
              {essentials.slice(0, 4).map((e, i) => {
                return <li key={`${e.item}-${i}`}>{e.item}</li>
              })}
            </List>
            <List>
              {essentials.slice(4, 8).map((e, i) => {
                return <li key={`${e.item}-${i}`}>{e.item}</li>
              })}
            </List>
          </ListRow>
        </RentlioEssentials>
      </Container>
    </CareerContentWrapper>
  )
}

export default CareerContent
