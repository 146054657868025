import React, { useState, useEffect } from 'react'
import { CareerJobListWrapper, JobPositionsList, Job, NoJobPosition, Loader } from './styles'

import Container from '../../UI/Container'
import { LocaleConsumer } from '../../Layout'

const CareerJobList = ({ data, location }) => {
  // const [talentlyftDataFiltered, setTalentlyftDataFiltered] = useState([])
  const [loading, setLoading] = useState(true)
  // useEffect(() => {
  //   fetch('https://api.talentlyft.com/v2/public/rentlio/jobs')
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setLoading(false), setTalentlyftDataFiltered(data.Results.filter((data) => data.Title != 'Open Application'))
  //     })
  // }, [])

  const {
    acf: { open_positions }
  } = data[0].node

  const JobPositionItem = ({ position, text }) => {
    const { job_position, work_location, slug } = position
    return (
      <Job text={text} centerText={work_location === 'not-avaliable'}>
        <div>
          <h6>Marketing Project Manager</h6>
        </div>
      </Job>
    )
  }
  return (
    <LocaleConsumer>
      {({ i18n }) => (
        <CareerJobListWrapper>
          <Container>
            <JobPositionsList>
              {/* TALENTLYFT OPCIJA: */}
              {/* {loading ? (
                <Loader />
              ) : (
                talentlyftDataFiltered.map((data) => (
                  <Job text={i18n.jobApply} href={data.ShortlinkUrl} target="_blank" rel="noreferrer" key={data.Id}>
                    <div>
                      <h6>{data.Title}</h6>
                    </div>
                  </Job>
                ))
              )} */}

              {/* 
              <Job
                text=""
                href="https://rentlio.talentlyft.com/jobs/open-application-wim/new"
                target="_blank"
                rel="noreferrer"
                style={{ cursor: 'pointer' }}
              >
                <div>
                  <h6>{i18n.jobApplyMessageCareer}</h6>
                </div>{' '}
              </Job> */}
              <Job
                text=""
                href="https://rentlio.peopleforce.io/careers/v/78484-open-application"
                target="_blank"
                rel="noreferrer"
                style={{ cursor: 'pointer' }}
              >
                <div>
                  <h6>{i18n.jobApplyMessageCareer}</h6>
                </div>{' '}
              </Job>
            </JobPositionsList>
          </Container>
        </CareerJobListWrapper>
      )}
    </LocaleConsumer>
  )
}

export default CareerJobList
