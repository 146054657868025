import styled from 'styled-components'

export const CareerJobListWrapper = styled.div`
  padding: 2rem 0;
  background-color: ${({ theme }) => theme.colors.veryDarkBlue};
`
export const Loader = styled.div`
  position: relative;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 70px auto;
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
export const JobPositionsList = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 0 5rem 0;

  & a {
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 545px) {
    li a {
      padding: 1rem;

      h6 {
        font-size: 1.2rem;
      }
    }
    li::after {
      display: none;
    }
  }
  .hrefLink {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .talentlyft {
    margin: auto auto 0 auto;
    max-width: none;
  }
  .job-filters {
    display: none !important;
  }
  .tl-jobs li {
    padding: 1.5rem 1rem;
  }
  .talentlyft .tl-jobs {
    padding: 0;
  }
  .tl-job {
    background: rgb(9, 40, 68);
    box-shadow: rgb(0 0 0 / 5%) 0px 10px 20px;
    border: 1px solid rgba(29, 158, 217, 0.5);
    border-radius: 8px;
    cursor: pointer;
    transition: box-shadow 0.2s ease-in-out 0s;
    position: relative;
    margin-bottom: 2rem;
  }
  .tl-job:hover {
    box-shadow: rgb(0 133 255 / 20%) 0px 4px 10px;
    background-color: rgb(9, 40, 68) !important;
    & .tl-address:after {
      right: 4%;
      opacity: 1;
    }
  }
  .sk-folding-cube {
    display: none !important;
  }
  .tl-jobs a {
    padding: 1.5rem;
  }
  .tl-title .tl-title-text {
    font-size: 1.42rem !important;
    line-height: 1.4rem;
  }
  .tl-address img {
    display: none !important;
  }
  .tl-address span {
    display: none !important;
  }
  .tl-address:after {
    font-size: 1.2rem !important;
    font-weight: bold !important;
    color: rgb(29, 158, 217);
    content: 'POGLEDAJ OGLAS' !important;
    position: absolute;
    right: -1%;
    transition: all 0.2s ease-in-out;
    opacity: 0;
  }
  a {
    color: ${({ theme }) => theme.colors.vividBlue};
  }
  @media screen and (max-width: 425px) {
    margin: 1rem 0;
  }
  @media screen and (max-width: 545px) {
    margin: 1rem 0;
    .tl-title .tl-title-text {
      font-size: 1.2rem !important;
    }
    .tl-jobs a {
      padding: 0rem;
    }
    .tl-jobs li {
      line-height: 0.5rem;
      padding: 1.5rem 1rem 0.8rem 1rem;
    }
    .tl-job {
      margin-bottom: 1rem;
    }
    .tl-address:after {
      display: none;
    }
  }
`
export const Job = styled.a`
  /* background: ${({ theme }) => theme.colors.almostAsDarkBlue}; */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(29, 158, 217, 0.5);
  border-radius: 8px;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
  position: relative;

  div {
    display: flex;
    padding: 2.7rem 2.5rem;
    justify-content: space-between;
    align-items: center;

    &::after {
      ${({ text }) => `content:"${text}"`};
      color: ${({ theme }) => theme.colors.vividBlue};
      font-size: 1.2rem;
      font-weight: 700;
      line-height: 1.71rem;
      text-transform: uppercase;
      /* position: absolute;
      top: 0;
      right: 0; */
      /* transform: ${({ centerText }) => (centerText ? `translate(0%, 40px);` : `translate(0%, 50px);`)};
      transition: all 0.2s ease-in-out;
      opacity: 0; */
    }
    @media screen and (max-width: 545px) {
      &:first-child {
        padding-right: 118px;
      }
      padding: 0.5rem 1rem;
      flex-direction: column;
      align-items: flex-start;
      h6 {
        color: ${({ theme }) => theme.colors.lightestGrey};
        font-family: 'Barlow';
        font-size: 1.2rem;
        font-weight: 500;
      }
      &::after {
        ${({ text }) => `content:"${text}"`};
        color: ${({ theme }) => theme.colors.vividBlue};
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.41rem;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);

    /* div::after {
      opacity: 1;
      transform: ${({ centerText }) => (centerText ? `translate(-25%, 40px);` : `translate(-65%, 50px);`)};
    } */
  }

  h6 {
    color: ${({ theme }) => theme.colors.lightestGrey};
    font-family: 'Barlow';
    font-size: 1.42rem;
    font-weight: 500;
  }

  span {
    color: ${({ theme }) => theme.colors.lightGrey};
  }
`
