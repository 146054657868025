import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Header from 'components/Header'
import Footer from 'components/Footer'
import CareerIntro from 'components/Career-screen/Career-intro'
import CareerJobList from 'components/Career-screen/Career-job-list'
import CareerContent from 'components/Career-screen/Career-content'

const MainContent = styled.main`
  background-color: #ffffff;
`

const Career = ({ data, pageContext, location }) => {
  const {
    career: {
      title,
      excerpt,
      featured_media,
      yoast_meta: {
        yoast_wpseo_title,
        yoast_wpseo_metadesc,
        yoast_wpseo_facebook_title,
        yoast_wpseo_facebook_description,
        yoast_wpseo_facebook_type,
        yoast_wpseo_twitter_title,
        yoast_wpseo_twitter_description
      },
      acf: {
        intro_title_careers,
        intro_description_careers,
        column_left_careers,
        column_right_careers,
        essentials_title,
        essentials
      }
    },
    job_positions: { edges: position }
  } = data

  const featuredImage = featured_media !== null ? featured_media?.localFile?.childImageSharp : null
  const seo = {
    title: yoast_wpseo_title || title,
    description: yoast_wpseo_metadesc || excerpt.replace(/<\/?p[^>]*>/g, ''),
    lang: pageContext.locale,
    image: featuredImage ? featuredImage.resize : null,
    pathname: location.pathname,
    facebook: { yoast_wpseo_facebook_title, yoast_wpseo_facebook_description, yoast_wpseo_facebook_type },
    twitter: { yoast_wpseo_twitter_title, yoast_wpseo_twitter_description }
  }

  return (
    <Layout locale={pageContext.locale}>
      <SEO
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
        lang={seo.lang}
        image={seo.image}
        facebook={seo.facebook}
        twitter={seo.twitter}
        article
        location={location}
      />
      <Header mode="light" locale={pageContext.locale} color={'#104468'} location={location} />
      <CareerIntro locale={pageContext.locale} data={{ intro_title_careers, intro_description_careers }} />
      <MainContent>
        <CareerJobList data={position} location={location.pathname} />
        <CareerContent content={{ column_left_careers, column_right_careers, essentials_title, essentials }} />
      </MainContent>
      <Footer locale={pageContext.locale} location={location} />
    </Layout>
  )
}

export default Career

export const query = graphql`
  query($id: Int, $locale: String!) {
    career: wordpressPage(wordpress_id: { eq: $id }, lang: { eq: $locale }) {
      title
      excerpt
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_facebook_type
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(quality: 85, maxWidth: 1300) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
      acf {
        intro_title_careers
        intro_description_careers
        column_left_careers {
          title
          description
        }
        column_right_careers {
          title
          description
        }
        essentials_title
        essentials {
          item
        }
      }
    }
    job_positions: allWordpressWpJobs(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          acf {
            title
            text
            link_text
            url
            open_positions {
              job_position
              work_location
              slug
            }
          }
        }
      }
    }
  }
`
